export const option = [
  {
    name: '',
    value: ''
  },
  {
    name: 'loan',
    value: 'loan'
  },
  {
    name: 'client',
    value: 'client'
  }
]
