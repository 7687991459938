import React, { useEffect, useState } from 'react'
import { useTranslation } from 'react-i18next'
import { Button, Card, CardBody, Input, Table, Alert, Label } from 'reactstrap'
import BreadCrumb from 'src/Components/Common/BreadCrumb'
import UiContent from 'src/Components/Common/UiContent'
import Select from 'src/Components/Common/Select'
import { Link } from 'react-router-dom'
import EditModal from './EditModal'
import { option } from './consts'
import {
  useGetTagTypesQuery,
  useCreateTagTypeMutation,
  useDeleteTagTypeMutation
} from 'src/services/tags'

const ALERT_DEBOUNCE = 3000

const headers = {
  tag: { label: 'Метки' },
  type: { label: 'Тип проставления' }
}

export const Tags = () => {
  const { t } = useTranslation()
  const [tag, setTag] = useState('')
  const [type, setType] = useState(option[0])
  const [borderColor, setBorderColor] = useState('#405189')
  const [textColor, setTextColor] = useState('#000000')
  const [success, setSuccess] = useState(false)
  const [error, setError] = useState(null)
  const [isEditModalOpen, setIsEditModalOpen] = useState(false)
  const [selectedTag, setSelectedTag] = useState(null)

  const { data: tags = [], refetch: loadTags } = useGetTagTypesQuery()
  const [createTagType] = useCreateTagTypeMutation()
  const [deleteTagType] = useDeleteTagTypeMutation()

  const title = t('Custom Tags')
  document.title = title

  useEffect(() => {
    if (success) {
      setTimeout(() => setSuccess(false), ALERT_DEBOUNCE)
    }
    if (error) {
      setTimeout(() => setError(null), ALERT_DEBOUNCE)
    }
  }, [success, error])

  const createTag = async () => {
    try {
      const requestData = {
        tag: tag.trim(),
        entity_type: type.value || type,
        border_color: borderColor,
        text_color: textColor
      }

      const result = await createTagType(requestData).unwrap()

      if (result.successful) {
        setTag('')
        setType(option[0])
        setBorderColor('#405189')
        setTextColor('#000000')
        setSuccess(true)
        setError(null)
        loadTags()
      }
    } catch (error) {
      console.error('Error creating tag:', error)
      setSuccess(false)

      if (error.data && error.data.detail) {
        if (typeof error.data.detail === 'object') {
          setError({ ddetail: t('Error') })
        } else {
          setError({ detail: error.data.detail })
        }
      }
    }
  }
  const handleDelete = async (tagId) => {
    try {
      const result = await deleteTagType(tagId).unwrap()
      if (result.successful) {
        loadTags()
      }
    } catch (error) {
      console.error('Error deleting tag:', error.data)
    }
  }

  const handleEdit = (tag) => {
    setSelectedTag({
      ...tag,
      border_color: tag.border_color || '#405189',
      text_color: tag.text_color || '#000000'
    })
    setIsEditModalOpen(true)
  }

  const toggleEditModal = () => {
    setIsEditModalOpen(!isEditModalOpen)
  }

  const handleUpdate = () => {
    loadTags()
  }

  return (
    <React.Fragment>
      <UiContent />
      <div className='page-content'>
        <BreadCrumb title={title} />
        <Card>
          <CardBody>
            <div style={{ display: 'flex', flexWrap: 'wrap', gap: '30px' }}>
              <Input
                type='text'
                style={{ width: '300px' }}
                value={tag}
                onChange={(event) => setTag(event.target.value)}
                placeholder={t('Tag name')}
              />
              <Select
                value={type}
                options={option}
                onChange={(value) => {
                  setType(value)
                }}
              />
              <div style={{ display: 'flex', gap: '10px' }}>
                <Label>Цвет рамки</Label>
                <Input
                  type='color'
                  label={'Цвет рамки'}
                  style={{ width: '50px', padding: '5px' }}
                  value={borderColor}
                  onChange={(e) => setBorderColor(e.target.value)}
                />
                <Label>Цвет текста</Label>
                <Input
                  type='color'
                  label={'Цвет текста'}
                  style={{ width: '50px', padding: '5px' }}
                  value={textColor}
                  onChange={(e) => setTextColor(e.target.value)}
                />
              </div>
            </div>
            <Button
              color='success'
              type='button'
              onClick={createTag}
              style={{ marginTop: '30px', marginBottom: '30px' }}
              disabled={!tag || !type}
            >
              {t('Save')}
            </Button>
            {success && <Alert color='success'>{t('Done')}</Alert>}
            {error && (
              <Alert color='danger'>
                {typeof error.detail === 'object'
                  ? JSON.stringify(error.detail)
                  : error.detail || t('Error')}
              </Alert>
            )}
          </CardBody>
        </Card>
        <h4>{'Редактирование меток'}</h4>
        <Table hover className='table-sm align-middle table-nowrap mb-0'>
          <thead>
            <tr>
              {Object.keys(headers).map((header) => (
                <th className='ps-3' key={header} scope='col'>
                  {t(headers[header].label || header)}
                </th>
              ))}
              <th scope='col'>{t('Actions')}</th>
            </tr>
          </thead>
          <tbody>
            {tags &&
              tags.length > 0 &&
              tags.map((entity) => {
                return (
                  <tr key={entity.id}>
                    {Object.keys(headers).map((fieldName) => {
                      if (fieldName === 'tag')
                        return (
                          <td className='ps-3 w-25' key={fieldName}>
                            <span
                              style={{
                                color: entity.text_color || '#405189',
                                border: `1px solid ${
                                  entity.border_color || '#000000'
                                }`,
                                padding: '2px 8px',
                                borderRadius: '4px'
                              }}
                            >
                              {entity[fieldName]}
                            </span>
                          </td>
                        )
                      return (
                        <td className='ps-3 w-75' key={fieldName}>
                          {entity[fieldName]}
                        </td>
                      )
                    })}
                    <td>
                      <div className='hstack gap-3 flex-wrap'>
                        <Link
                          to={'#'}
                          onClick={() => handleEdit(entity)}
                          className='link-success fs-15'
                        >
                          <i className='ri-edit-2-line'></i>
                        </Link>
                        <Link
                          to='#'
                          onClick={() => handleDelete(entity.id)}
                          className='link-danger fs-15'
                        >
                          <i className='ri-delete-bin-line'></i>
                        </Link>
                      </div>
                    </td>
                  </tr>
                )
              })}
          </tbody>
        </Table>
      </div>
      <EditModal
        isOpen={isEditModalOpen}
        toggle={toggleEditModal}
        entity={selectedTag}
        onUpdate={handleUpdate}
      />
    </React.Fragment>
  )
}
