import { api } from './api'

type Tag = {
  created_at: string
  tag_type_id: number
  tag: string
  border_color: string
  text_color: string
  client_id: string
  loan_id: string
  state: boolean
  hex?: string
}

type ClientTag = {
  id: number
  tag: string
}

type TagType = {
  id: number
  tag: string
  type: string
}

type TagTypeUpdate = {
  tag?: string
  type?: string
  border_color?: string
  text_color?: string
}

type TagResponse = {
  successful: boolean
  data: TagType[]
}

const tagsApi = api.injectEndpoints({
  endpoints: (builder) => ({
    getLoanTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/loans/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'LoanTags', id }]
    }),
    getLoansTags: builder.query<{ id: number; tag: string }[], void>({
      query: () => `crm/v1/tags/loans`
    }),
    getClientTags: builder.query<Tag[], string>({
      query: (id) => `crm/v1/tags/clients/${id}`,
      providesTags: (_result, _err, id) => [{ type: 'ClientTags', id }]
    }),
    getClientsTags: builder.query<ClientTag[], void>({
      query: () => '/crm/v1/tags/clients'
    }),
    setLoansTagsByID: builder.mutation<string, { id: string; body: number[] }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/loans/${id}/add`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['LoanTags']
    }),
    removeLoansTagsByID: builder.mutation<
      string,
      { id: string; body: number[] }
    >({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/loans/${id}/delete`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['LoanTags']
    }),
    setClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/add`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['ClientTags']
    }),
    removeClientsTagsByID: builder.mutation<void, { id: string; body: any }>({
      query: ({ id, body }) => ({
        url: `/crm/v1/tags/clients/${id}/delete`,
        method: 'POST',
        body: body
      }),
      invalidatesTags: () => ['ClientTags']
    }),

    getTagTypes: builder.query<TagType[], void>({
      query: () => `crm/v1/tags/types`
    }),

    createTagType: builder.mutation<
      TagResponse,
      { tag: string; entity_type: string }
    >({
      query: (data) => ({
        url: 'crm/v1/tags/types',
        method: 'POST',
        body: data
      })
    }),
    updateTagType: builder.mutation<
      TagResponse,
      { id: number; data: TagTypeUpdate }
    >({
      query: ({ id, data }) => ({
        url: `crm/v1/tags/types/${id}`,
        method: 'PUT',
        body: data
      })
    }),

    deleteTagType: builder.mutation<TagResponse, number>({
      query: (id) => ({
        url: `crm/v1/tags/types/${id}`,
        method: 'DELETE'
      })
    })
  })
})

export const {
  useGetTagTypesQuery,
  useCreateTagTypeMutation,
  useUpdateTagTypeMutation,
  useDeleteTagTypeMutation,
  useGetLoanTagsQuery,
  useGetClientTagsQuery,
  useGetLoansTagsQuery,
  useGetClientsTagsQuery,
  useSetLoansTagsByIDMutation,
  useRemoveLoansTagsByIDMutation,
  useSetClientsTagsByIDMutation,
  useRemoveClientsTagsByIDMutation
} = tagsApi
